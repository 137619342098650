<template>
  <div id="say-yes">
    <h1>{{ question }}</h1>
    <img src="@/assets/gif/lei.jpg" />
    <div class="contaner-buttons">
      <el-button @mouseenter="saidNo()" :style="isHover ? 'position: absolute' : ''" @click="saidNo()" class="no-button"
        type="danger">NO</el-button>
      <el-button class="yes-button" @click="saidYes()" type="primary">SI</el-button>
    </div>
  </div>
  
</template>
  
<script>
import firebase from "firebase"

export default {
  name: 'SayYes',
  components: {
  },
  props: {
  },
  data() {
    return {
      b: null,
      isHover: false,
      isAnswered: false
    }
  },
  mounted() {
    if (!this.isLogged) {
      this.$router.push({ name: "secretWord" })
    }
    this.b = document.querySelector("button");
  },
  computed: {
    ip() {
      return this.$store.state.ip
    },
    isLogged() {
      return this.$store.state.isLogged
    },
    question() {
      return process.env.VUE_APP_PROJECT_QUESTION
    }
  },
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    saidYes() {
      if (!this.isAnswered) {
        var collection = firebase.firestore().collection(process.env.VUE_APP_FIREBASE_PROJECT_TITLE)
        var date = new Date()
        var fullYearDate = date.getFullYear() + "-" + date.getUTCMonth() + "-" + date.getDate()
        collection.doc("entered").collection("/" + fullYearDate).add({
          ip: this.ip,
          date: date,
          orderDate: fullYearDate,
          isMobile: this.isMobile(),
          response: true,
          userAgent: navigator.userAgent
        })
      
        this.isAnswered = true
      }
      this.$message({
        showClose: true,
        message: 'Chiamami...',
        type: 'success'
      })
    },
    saidNo() {
      this.isHover = true
      var i = Math.floor(Math.random() * 500) + 1;
      var j = Math.floor(Math.random() * 500) + 1;
      this.b.style.left = i + "px";
      this.b.style.top = j + "px";
    }
  }
}
</script>
  
<style lang="less">
#say-yes {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  img {
    width: 100%;
    max-width: 500px;
    margin-bottom: 1rem;
  }

  .contaner-buttons {
    display: flex;

    .no-button {
      display: block;
    }
    .yes-button {
      margin-left: 30px;
    }
  }
}
</style>
  